<template>
    <div class="has-text-centered">
        <div class="home-title">
            <h1 v-if="optionSelected.title == 'PYSE'" class="title title-mobile has-text-white mb-5">{{ optionSelected.title }}</h1>
            <figure v-else class="image is-flex is-inline-block logo-home">
                <img src="../../../assets/logo-metas-home.png" />
            </figure>
        </div>
        <div>
            <p class="mt-5 mb-5">
                {{ optionSelected.text }}
            </p>
        </div>
        <button class="button button-home-mobile mt-5">INTRANET</button>
    </div>
</template>

<script>
const options = {
  PYSE: {
    title: "PYSE",
    text: "Más de 30 años de experiencia en ingeniería eléctrica – Somos tu aliado de confianza para diseñar, implementar y mantener proyectos eléctricos.",
  },
  GA: {
    title: "GRUPO ALBARRÁN",
    text: "Más de 30 años de experiencia – Brindamos soluciones efectivas de diseño,instalación y mantenimiento eléctrico.",
  },
};

export default {
  name: "HomeMobile",
  data: () => {
    return {
      optionSelected: options.PYSE,
    };
  },
  mounted() {
    setInterval(() => {
      if (this.optionSelected.title === options.PYSE.title) {
        this.optionSelected = options.GA;
      } else {
        this.optionSelected = options.PYSE;
      }
    }, 5000);
  }
};
</script>

<style>
.home-title {
    height: 10hv;
}

.title-mobile {
  font-size: 64px !important;
  min-height: 100px;
}

.text-box {
  width: 450px;
}

.logo-home {
  width: 60%;
  height: 60%;
}

.button-home-mobile {
  width: 231px;
  height: 37px !important;
  background-color: rgba(217, 217, 217, 0.75) !important;
  border-color: transparent !important;
  border-radius: 5px !important;
  color: white !important;
  font-family: "Inter";
  font-size: 14px !important;
  letter-spacing: 0.105em;
}
</style>
