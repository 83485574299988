<template>
  <div class="column" :class="isMobile ? 'has-text-centered' : 'has-text-right'">
    <div class="menu">
        <div v-if="isComplete">
            <p v-for="page in pages" :key="page">
            <a :class="{ selected: selectedPage === page, 'is-dark': bgLight }" :href="`/${page}`">{{
                page.toUpperCase()
            }}</a>
            </p>
            <div class="mt-5">
            </div>
        </div>
        <div v-if="$route.name!=='home'" :class="isMobile ? 'menu-fixed-bottom' : ''">
          <p v-if="isMobile"><a href="/" class="back-home-menu" :class="{ 'is-dark': bgLight }">Volver a Inicio</a></p>
          <a v-for="page in pages" :key="page" :href="`/${page}`">
              <i
              class="fa"
              :class="[
                selectedPage === page ? 'fa-circle-thin' : 'fa-circle',
                !(page === 'nosotros' && isMobile) ? 'ml-4' : ''
              ]"
              aria-hidden="true"
              ></i>
          </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: ["selected-page", "is-complete", "bg-light", "is-mobile"],
  computed: { 
    pages() {
      const pages = ["nosotros", "servicios", "proyectos", "contacto"]
      if (this.isMobile) {
        pages.push("clientes")
      }
      return pages
    }
  }
};
</script>

<style scoped>
.menu {
  font-size: 11px !important;
  letter-spacing: 0.15em;
  line-height: 35px;
}

.menu a {
  color: white;
  margin-bottom: 50px;
}

.menu a:hover {
  color: white;
  text-decoration: underline;
}

.menu a.selected {
  text-decoration: underline;
}

.fa {
    color: #D9D9D9 !important;
}

.is-dark {
    color: #5C5C5C !important;
}

.menu-fixed-bottom {
  position: fixed;
  bottom: 2%;
  left: -1px;
  width: 100%;
}

.back-home-menu {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
</style>