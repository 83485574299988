<script>
export const GrupoAlbarran = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div class="mt-5">
          <h1 class="nosotros-title">GRUPO ALBARRÁN</h1>
          <div class="space-1"></div>
          <p class="nosotros-text">
            Grupo Albarrán y Asociados es la alianza de confianza para
            inversionistas, arquitectos y desarrolladores. Contamos con cientos
            de obras concluidas exitosamente con los más altos niveles de
            calidad, con un gran número de licitaciones ganadas en concursos
            nacionales de primer nivel y con una solidéz financiera garantizada
            que nos permite respaldar proyectos de gran envergadura. Nuestras
            alianzas estratégicas con fabricantes y grandes distribuidores nos
            proporcionan una importante ventaja en cuanto a eficiencia y precios
            que ponemos al servicio de nuestros clientes.
          </p>
        </div>
      </div>
    );
  },
};

export const Mision = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div>
          <div class="space-1"></div>
          <h1 class="nosotros-title mb-5">Misión</h1>
          <div class="div-line"></div>
          <p class="nosotros-text mt-4">
            Ser una empresa experta y líder que provee alta tecnología y
            seguridad en proyectos de ingeniería de instalación eléctrica,
            anticipándose siempre a las necesidades de sus clientes y respetando
            el medio ambiente.
          </p>
        </div>
      </div>
    );
  },
};

export const Vision = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div>
          <div class="space-1"></div>
          <h1 class="nosotros-title mb-5">Visión</h1>
          <div class="div-line"></div>
          <p class="nosotros-text mt-4">
            Ser una empresa de ingeniería eléctrica de mayor vanguardia
            tecnológica, cuidando su entorno y siendo el mejor lugar para
            trabajar y hacer negocios.
          </p>
        </div>
      </div>
    );
  },
};

export const Valores = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div>
          <div class="space-1"></div>
          <h1 class="nosotros-title mb-5">Valores</h1>
          <div class="div-line"></div>
          <p class="nosotros-text mt-4">
            Fomentando una cultura de servicio en toda la empresa y compromiso
            con las necesidades y los éxitos de los demás, nos aseguramos de que
            nuestras relaciones con los clientes estén basadas en la misma
            cultura y valores.
          </p>
        </div>
      </div>
    );
  },
};

export const ValoresDetail = {
  render() {
    return (
      <div class="column">
        <div class="space-3"></div>
        <div class="columns">
          <div class="column">
            <div class="nosotros-valores-column">
              <h1 class="nosotros-subtitle">Honestidad</h1>
              <p class="nosotros-text-columns mt-3">
                Ser responsable, sincero y claro en las acciones, decisiones y
                comportamientos que tenemos a la hora de hacer negocios y
                convivir en armonía con mis compañeros, clientes y entorno.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="nosotros-valores-column">
              <h1 class="nosotros-subtitle">Seriedad</h1>
              <p class="nosotros-text-columns mt-3">
                Actuar con formalidad, de manera sensata y con buen juicio con
                nuestros compañeros, clientes y entorno.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="nosotros-valores-column">
              <h1 class="nosotros-subtitle">Respeto</h1>
              <p class="nosotros-text-columns mt-3">
                Mostrar interés, cuidado, aprecio y respeto genuinos por el
                trabajo y nuestros compañeros, así también con las comunidades
                con las que trabajamos.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="nosotros-valores-column">
              <h1 class="nosotros-subtitle">Excelencia</h1>
              <p class="nosotros-text-columns mt-3">
                Garantizar en que nuestros profesionales colaboran con una
                experiencia de primer nivel, un excelente servicio y un esfuerzo
                incesante por contribuir con sus resultados.
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="nosotros-valores-column">
              <h1 class="nosotros-subtitle">Incluyente</h1>
              <p class="nosotros-text-columns mt-3">
                Ser humilde para aceptar nuevas, diferentes y mejores formar de
                hacer las cosas y pensar, siempre compartiendo la información y
                colaborando con los demás de forma positiva.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="nosotros-valores-column">
              <h1 class="nosotros-subtitle">Innovación</h1>
              <p class="nosotros-text-columns mt-3">
                Actuar y pensar siempre de forma creativa y diferente para
                desarrollar ideas novedosas y simples.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="nosotros-valores-column">
              <h1 class="nosotros-subtitle">Integridad</h1>
              <p class="nosotros-text-columns mt-3">
                Siempre actuar como visionario del negocio y tratar el dinero de
                la compañía como si fuera propio. Ser leal a nuestras ideas,
                negocio y clientes y sentir orgullo por trabajar en nuestra
                empresa.
              </p>
            </div>
          </div>
          <div class="column">
            <div class="nosotros-valores-column ">
              <h1 class="nosotros-subtitle">Puntualidad</h1>
              <p class="nosotros-text-columns mt-3">
                Respetar el tiempo de nuestros clientes y compañeros, conocer la
                importancia de hacer las cosas cuando se esperan, como se
                esperan y donde se esperan.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Carta = {
  data() {
    return {
      image: require("../../assets/armando-albarran.png"),
    };
  },
  render() {
    return (
      <div class="column">
        <div class="columns">
          <div class="column is-three-fifths is-offset-one-fifth has-text-justified">
            <div class="space-3"></div>
            <p class="nosotros-cart-text">
              “Tenemos una inversión continuada en medios técnicos y materiales,
              en Albarrán y Asociados ponemos especial énfasis en el equipo
              humano invirtiendo en su desarrollo profesional y personal.
            </p>
            <p class="nosotros-cart-text mt-5">
              Para ello contamos con un amplio programa formativo en novedades
              técnicas, software técnico de cálculo y diseño, mejora de
              competencias tanto técnicas como personales, idiomas, calidad y
              mejora continua, y formación específica en prevención de riesgos y
              seguridad laboral.”
            </p>
            <div class="has-text-centered mt-5">
              <figure class="image is-inline-block">
                <img src={this.image} />
              </figure>
              <p class="nosotros-cart-text">Ing. Armando Albarrán</p>
              <p class="nosotros-cart-text">Director General</p>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export default GrupoAlbarran;
</script>

<style>
.section {
  padding-bottom: 0 !important;
}

h1.nosotros-title {
  color: white;
  font-size: 60px;
  font-weight: 600;
}

h1.nosotros-subtitle {
  color: black;
  font-size: 24px;
  font-weight: 600;
}

p.nosotros-text {
  color: white;
  font-size: 15px;
  line-height: 18px;
}

p.nosotros-text-columns {
  color: black;
  font-size: 11px;
  line-height: 13.31px;
}

p.nosotros-cart-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
}

@media only screen and (min-height: 650px) {
  .space-1 {
    height: 10vh;
  }
  .space-2 {
    height: 45px;
  }
  .space-3 {
    height: 100px;
  }
}

.div-line {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: white;
}

.nosotros-valores-column {
  max-width: 250px;
}

.cart-section {
  max-width: 400px;
}
</style>