<script>
export const Contacto = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div class="mt-5">
          <h1 class="contacto-title">CONTACTO</h1>
          <div class="space-1"></div>
          <p class="contacto-text mt-5">
            Comunícate con nosotros.
          </p>
          <p class="contacto-text">
            Nuestra atención telefónica está disponible para responder a tus preguntas
          </p>
          <div class="div-line-contacto mt-5"></div>
        </div>
      </div>
    );
  },
};

export const Form = {
  render() {
    return (
      <div class="column is-three-quarters">
        <h1 class="contacto-subtitle">Contáctanos hoy</h1>
        <div class="columns mt-5">
          <div class="column">
            <input class="input contacto-input" type="text" placeholder="Nombre" />
            <input class="input contacto-input mt-3" type="text" placeholder="E-mail" />
            <input class="input contacto-input mt-3" type="text" placeholder="Asunto" />
            <textarea class="textarea contacto-input mt-3" placeholder="Mensaje"></textarea>
            <button class="button button-contacto mt-3">Enviar</button>
          </div>
          <div class="column">
            <p class="contacto-text-dark">
                Ya sea una pregunta sobre soluciones de diseño, 
                una nueva consulta comercial o simplemente una solicitud 
                de chat informal, siempre encontrará a Grupo Albarrán 
                disponible a través de este formulario de contacto.
            </p>
            <h2 class="contacto-subtitle-small mt-3">DIRECCIÓN</h2>
            <p class="contacto-text-dark mt-1">Antara Polanco</p>
            <p class="contacto-text-dark">Av Ejército Nacional 843-B</p>
            <p class="contacto-text-dark">Granada, 11520</p>
            <p class="contacto-text-dark">Ciudad de México</p>
            <h2 class="contacto-subtitle-small mt-3">CONTACTO</h2>
            <p class="contacto-text-dark mt-1">hola@albarran.com</p>
            <p class="contacto-text-dark">+52 (55) 59 85 07 00</p>
            <p class="contacto-text-dark mt-3">
                Acepto la <span class="has-text-weight-bold">Politica de privacidad</span>
            </p>
          </div>
        </div>
        <div class="div-line-contacto mt-5"></div>
      </div>
    );
  },
};

export default Contacto;
</script>

<style>
.button-contacto {
    border-style: none !important;
    background-color: #D9D9D9 !important;
    color: #838181 !important;
    font-size: 12px !important;
    width: 100px !important;
    height: 37px !important;
}

.contacto-input {
    border-style: none !important;
    box-shadow: none !important;
    background-color: #F5F5F5 !important;
    font-size: 12px !important;
}

h1.contacto-title {
  color: white;
  font-size: 60px;
  font-weight: 600;
  line-height: 72.61px;
}

h1.contacto-subtitle {
  color: #838181 !important;
  font-size: 32px;
  font-weight: 600;
}

h2.contacto-subtitle-small {
  color: #838181 !important;
  font-size: 12px;
  font-weight: 600;
}

p.contacto-text {
  color: white;
  font-size: 20px;
  line-height: 24px;
}

p.contacto-text-dark {
  color: #838181;
  font-size: 12px;
  line-height: 15px;
}

.div-line-contacto {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(217, 217, 217, 0.25);
}

.div-line-contacto-dark {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(217, 217, 217, 0.25);
}
</style>