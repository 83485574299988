<script>
export const Servicios = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div class="mt-5">
          <h1 class="servicios-title">SERVICIOS</h1>
          <div class="space-1"></div>
          <p class="servicios-text">
            Grupo Albarrán y Asociados ofrece una cartera coordinada de servicios especialmente para 
            la seguridad de las instalaciones comerciales, residenciales y industriales. 
            Brindamos servicios de Instalación e infraestructura eléctrica en las siguientes áreas:
          </p>
        </div>
      </div>
    );
  },
};

export const AreaElectrica = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div>
          <div class="space-3"></div>
          <h1 class="servicios-title mb-5 has-text-dark">Área Eléctrica</h1>
          <div class="columns">
            <div class="column content">
                <ul class="servicios-list mt-4 has-text-dark">
                    <li>Sistemas de puesta a tierra</li>
                    <li>Instalaciones eléctricas en media y baja tensión</li>
                    <li>Sistemas internos y externos de iluminación</li>
                    <li>Acometidas eléctricas, redes eléctricas internas y externas</li>
                    <li>Colocación de biométricos</li>
                    <li>Canalizaciones vacías para voz-datos</li>
                    <li>Colocación de placas en apagadores</li>
                    <li>Conexión de mamparas</li>
                    <li>Levantamientos y mantenciones eléctricas</li>
                </ul>
            </div>
            <div class="column content">
                <ul class="servicios-list mt-4 has-text-dark">
                    <li>Colocación de transformadores y tableros TGN</li>
                    <li>Diseño ejecutivo para las instalaciones eléctricas de alumbrado</li>
                    <li>Arreglo de equipos y canalizaciones vacías paralas instalaciones especiales</li>
                    <li>Elaboración de soporte para instalaciones</li>
                    <li>Suministro, colocación, mano de obra,  movimientos dentro de la obra</li>
                    <li>Elaboración de soporte para instalación de canalizaciones en losa.</li>
                    <li>Parametrización de ccm’s y calibración de interruptores electromagneticos</li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Servicios1 = {
  render() {
    return (
      <div class="column">
          <div class="columns">
            <div class="column content">
                <h1 class="servicios-subtitle">Subestaciones Media Tensión</h1>
                <ul class="servicios-list mt-4">
                    <li>Sistemas de puesta a tierra</li>
                    <li>Instalaciones eléctricas en media y baja tensión</li>
                    <li>Sistemas internos y externos de iluminación</li>
                    <li>Acometidas eléctricas, redes eléctricas internas y externas</li>
                    <li>Colocación de biométricos</li>
                </ul>
            </div>
            <div class="column content">
                <h1 class="servicios-subtitle">Instalaciones Eléctricas Internas</h1>
                <ul class="servicios-list mt-4">
                    <li>Canalizaciones vacías para voz-datos</li>
                    <li>Colocación de placas en apagadores</li>
                    <li>Conexión de mamparas</li>
                    <li>Levantamientos y mantenciones eléctricas</li>
                    <li>Colocación de transformadores y tableros TGN</li>
                    <li>Diseño ejecutivo para las instalaciones eléctricas de alumbrado</li>
                </ul>
            </div>
            <div class="column content">
                <h1 class="servicios-subtitle">Diseño</h1>
                <ul class="servicios-list mt-4">
                    <li>Arreglo de equipos y canalizaciones vacías para las instalaciones especiales</li>
                    <li>Elaboración de soporte para instalaciones</li>
                    <li>Suministro, colocación, mano de obra, movimientos dentro de la obra</li>
                    <li>Elaboración de soporte para instalación de canalizaciones en losa.</li>
                    <li>Colocación de transformadores y tableros TGN</li>
                    <li>Parametrización de ccm’s y calibración de interruptores electromagneticos</li>
                </ul>
            </div>
          </div>
        </div>
    );
  },
};

export const Servicios2 = {
  render() {
    return (
      <div class="column">
          <div class="space-1"></div>
          <div class="columns">
            <div class="column content">
                <h1 class="servicios-subtitle-dark">Subestaciones de Media y Baja Tensión</h1>
                <ul class="servicios-list mt-4 has-text-dark">
                    <li>Suministro e instalación de transformador</li>
                    <li>Suministro e instalación de elementos de  protección</li>
                    <li>Suministro e instalación de equipos de medida</li>
                    <li>Suministro e instalación de celdas</li>
                </ul>
            </div>
            <div class="column content">
                <h1 class="servicios-subtitle-dark">Red de media y baja tensión</h1>
                <ul class="servicios-list mt-4 has-text-dark">
                    <li>Suministro e instalación de elementos  que componen la red</li>
                    <li>Instalación de alumbrado público</li>
                    <li>Medición termografía</li>
                </ul>
            </div>
            <div class="column content">
                <h1 class="servicios-subtitle-dark">Sistema de puesta en tierra</h1>
                <ul class="servicios-list mt-4 has-text-dark">
                    <li>Medición de resistividad de terreno</li>
                    <li>Medición de resistividad de la malla de puesta a tierra</li>
                    <li>Diseño de la malla de puesta a tierra</li>
                    <li>Suministro e Instalación de los diferentes elementos que componen el Sistema de Puesta a Tierra</li>
                </ul>
            </div>
          </div>
        </div>
    );
  },
};

export const Mantenimiento = {
  render() {
    return (
      <div class="column is-three-quarters">
        <div>
          <div class="space-1"></div>
          <h1 class="servicios-title mb-5">Mantenimientos preventivos y correctivos</h1>
            <div class="content">
                <ul class="servicios-list mt-4">
                    <li>Termografía</li>
                    <li>Sistema de puesta a tierra: verificación de conexiones y medida de resistividad de tierra</li>
                    <li>Revisión y mantenimiento de subestaciones</li>
                    <li>Mantenimiento de luminarias</li>
                    <li>Mantenimiento de tableros</li>
                    <li>Marcación de gabinetes de voz y datos</li>
                    <li>Propuestas de ahorro de energía</li>
                </ul>
            </div>
        </div>
      <div class="div-line-nosotros mt-5"></div>
      </div>
    );
  },
};

export default Servicios;
</script>

<style>
h1.servicios-title {
  color: white;
  font-size: 57px;
  font-weight: 600;
  line-height: 72.61px;
}

h1.servicios-subtitle {
  color: white !important;
  font-size: 26px !important;
  font-weight: 600;
}

h1.servicios-subtitle-dark {
  color: #5C5C5C !important;
  font-size: 26px !important;
  font-weight: 600;
}

p.servicios-text {
  color: white;
  font-size: 13px;
  line-height: 18px;
}

ul.servicios-list {
  font-size: 13px;
  line-height: 17px;
}

.has-text-dark {
  color: #5C5C5C !important;
}

.div-line-nosotros {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(217, 217, 217, 0.25);
}
</style>