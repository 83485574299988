<script>
export const GrupoAlbarranMobile = {
  render() {
    return (
      <div class="margin-top-mobile-nosotros-big has-text-centered">
        <h1 class="nosotros-title-mobile">GRUPO ALBARRÁN</h1>
        <div class="space-mobile-home"></div>
        <p class="nosotros-text-mobile">
          Grupo Albarrán y Asociados es la alianza de confianza para
          inversionistas, arquitectos y desarrolladores.
        </p>
        <p class="nosotros-text-mobile">
          Contamos con cientos de obras concluidas exitosamente con los más
          altos niveles de calidad, con un gran número de licitaciones ganadas
          en concursos nacionales de primer nivel y con una solidéz financiera
          garantizada que nos permite respaldar proyectos de gran envergadura.
        </p>
        <p class="nosotros-text-mobile">
          Nuestras alianzas estratégicas con fabricantes y grandes
          distribuidores nos proporcionan una importante ventaja en cuanto a
          eficiencia y precios que ponemos al servicio de nuestros clientes.
        </p>
      </div>
    );
  },
};

export const MisionMobile = {
  render() {
    return (
      <div class="margin-top-mobile-nosotros has-text-centered">
        <h1 class="nosotros-title-mobile mb-5">MISIÓN</h1>
        <p class="nosotros-text-mobile">
          Ser una empresa experta y líder que provee alta tecnología y seguridad
          en proyectos de ingeniería de instalación eléctrica, anticipándose
          siempre a las necesidades de sus clientes y respetando el medio
          ambiente.
        </p>
        <h1 class="nosotros-title-mobile mt-6 mb-5">VISIÓN</h1>
        <p class="nosotros-text-mobile">
          Ser una empresa de ingeniería eléctrica de mayor vanguardia
          tecnológica, cuidando su entorno y siendo el mejor lugar para trabajar
          y hacer negocios.
        </p>
        <h1 class="nosotros-title-mobile mt-6 mb-5">VISIÓN</h1>
        <p class="nosotros-text-mobile">
          Fomentando una cultura de servicio en toda la empresa y compromiso con
          las necesidades y los éxitos de los demás, nos aseguramos de que
          nuestras relaciones con los clientes estén basadas en la misma cultura
          y valores.
        </p>
      </div>
    );
  },
};

export const Valores1Mobile = {
  render() {
    return (
      <div class="margin-top-mobile-nosotros has-text-centered">
        <h1 class="nosotros-title-mobile mb-5">Honestidad</h1>
        <p class="nosotros-text-mobile">
          Ser responsable, sincero y claro en las acciones, decisiones y
          comportamientos que tenemos a la hora de hacer negocios y convivir en
          armonía con mis compañeros, clientes y entorno.
        </p>
        <h1 class="nosotros-title-mobile mt-6 mb-5">Incluyente</h1>
        <p class="nosotros-text-mobile">
          Ser humilde para aceptar nuevas, diferentes y mejores formar de hacer
          las cosas y pensar, siempre compartiendo la información y colaborando
          con los demás de forma positiva.
        </p>
        <h1 class="nosotros-title-mobile mt-6 mb-5">Puntualidad</h1>
        <p class="nosotros-text-mobile">
          Respetar el tiempo de nuestros clientes y compañeros, conocer la
          importancia de hacer las cosas cuando se esperan, como se esperan y
          donde se esperan.
        </p>
      </div>
    );
  },
};

export const Valores2Mobile = {
  render() {
    return (
      <div class="margin-top-mobile-nosotros has-text-centered">
        <h1 class="nosotros-title-mobile mb-5">Seriedad</h1>
        <p class="nosotros-text-mobile">
          Actuar con formalidad, de manera sensata y con buen juicio con
          nuestros compañeros, clientes y entorno.
        </p>
        <h1 class="nosotros-title-mobile mt-6 mb-5">Innovación</h1>
        <p class="nosotros-text-mobile">
          Actuar y pensar siempre de forma creativa y diferente para desarrollar
          ideas novedosas y simples.
        </p>
        <h1 class="nosotros-title-mobile mt-6 mb-5">Excelencia</h1>
        <p class="nosotros-text-mobile">
          Garantizar en que nuestros profesionales colaboran con una experiencia
          de primer nivel, un excelente servicio y un esfuerzo incesante por
          contribuir con sus resultados.
        </p>
      </div>
    );
  },
};

export const Valores3Mobile = {
  render() {
    return (
      <div class="margin-top-mobile-nosotros has-text-centered">
        <h1 class="nosotros-title-mobile mb-5">Respeto</h1>
        <p class="nosotros-text-mobile">
          Mostrar interés, cuidado, aprecio y respeto genuinos por el trabajo y
          nuestros compañeros, así también con las comunidades con las que
          trabajamos.
        </p>
        <h1 class="nosotros-title-mobile mt-6 mb-5">Integración</h1>
        <p class="nosotros-text-mobile">
          Siempre actuar como visionario del negocio y tratar el dinero de la
          compañía como si fuera propio. Ser leal a nuestras ideas, negocio y
          clientes y sentir orgullo por trabajar en nuestra empresa.
        </p>
      </div>
    );
  },
};

export const CartaMobile = {
  data() {
    return {
      image: require("../../../assets/armando-albarran.png"),
    };
  },
  render() {
    return (
      <div class="margin-top-mobile-nosotros-big has-text-centered">
        <p class="nosotros-cart-text">
          “Tenemos una inversión continuada en medios técnicos y materiales, en
          Albarrán y Asociados ponemos especial énfasis en el equipo humano
          invirtiendo en su desarrollo profesional y personal.
        </p>
        <p class="nosotros-cart-text mt-5">
          Para ello contamos con un amplio programa formativo en novedades
          técnicas, software técnico de cálculo y diseño, mejora de competencias
          tanto técnicas como personales, idiomas, calidad y mejora continua, y
          formación específica en prevención de riesgos y seguridad laboral.”
        </p>
        <div class="has-text-centered mt-5">
          <figure class="image is-inline-block">
            <img src={this.image} />
          </figure>
          <p class="nosotros-cart-text">Ing. Armando Albarrán</p>
          <p class="nosotros-cart-text">Director General</p>
        </div>
      </div>
    );
  },
};

export default GrupoAlbarranMobile;
</script>

<style>
.nosotros-title-mobile {
  font-weight: 600;
  font-size: 28px;
  line-height: 29px;
}

.nosotros-text-mobile {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 1em;
}

.space-mobile-home {
  height: 60px;
}

p.nosotros-cart-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
}

.margin-top-mobile-nosotros {
    margin-top: 70px;
}

.margin-top-mobile-nosotros-big {
    margin-top: 140px;
}
</style>